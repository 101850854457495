<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'PurchaseSingler',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },
  mounted () {
    // 获取送货基地列表
    var newOption = []
    request('/api/livraison/base/getBaseInfoByName?' + 'baseName=' + '', 'get').then((res) => {
      res.data.forEach(item => {
        newOption.push({
          label: item.baseName,
          value: item.baseCode
        })
      })
      this.childData.searchList[1].option = newOption
    })
  },
  methods: {
    // 获取采购交付-送货需求列表数据
    myBidData () {
      let rkksTime = ''
      let rkjsTime = ''
      if (this.childData.searchData.rkTime) {
        rkksTime = dayjs(this.childData.searchData.rkTime[0]).format('YYYY-MM-DD HH:mm:ss')
        rkjsTime = dayjs(this.childData.searchData.rkTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData,
        releaseBeginTime: rkksTime,
        releaseEndTime: rkjsTime
      }
      request('/api/livraison/livrasion/getLivraListDataByUserId', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          // this.paginationData = { pageSize: pageSize, total: res.data.total }
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'AddPurchaseDemand' }, query: { livraisonCode: data.livraisonCode } })
      } else {
        this.deliverGoods(data)
      }
    },
    // 发货弹框
    deliverGoods (data) {
      this.$confirm('确认要发货吗？', '发货', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deliverFo(data)
      })
    },
    // 请求发货
    deliverFo (data) {
      request('/api/livraison/livrasion/updateDemandDetailStatusByCode?' + 'livraisonCode=' + data.livraisonCode, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.myBidData()
        }
      })
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
