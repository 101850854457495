import md5 from 'js-md5'
export var SearchList = [
  { labe: '送货单号', code: 'livraOrderCode', type: 'input' },
  {
    labe: '送货基地',
    code: 'baseCode',
    type: 'select',
    option: []
  },
  { labe: '供应商', code: 'providerInfo', type: 'input', placeholder: '编码/名称' },
  { labe: '需求单号', code: 'demandCode', type: 'input' },
  { labe: '采购订单编号', code: 'orderCode', type: 'input' },
  {
    labe: '预计送达日期',
    code: 'rkTime',
    type: 'daterange'
  },
  {
    labe: '送货单状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '已发布', value: '0' },
      { label: '已发货', value: '1' },
      { label: '已作废', value: '2' }
    ]
  },
  { labe: '采购员', code: 'buyer', type: 'input', placeholder: '采购员编码或名称' },
  {
    labe: '内向送货单号',
    code: 'reverseCode',
    type: 'input'/* ,
    option: [
      { label: '空', value: '0' },
      { label: '非空', value: '1' }
    ] */
  }
]
export var SearchData = {
  livraOrderCode: '',
  baseCode: '',
  providerInfo: '',
  demandCode: '',
  status: '',
  rkTime: ''
}

export var tableField = [
  { label: '送货单号', code: 'livraisonCode', type: 'input', width: '85%' },
  { label: '送货基地', code: 'demandBaseName', type: 'input', width: '50%' },
  { label: '供应商名称', code: 'providerName', type: 'input', width: '90%' },
  { label: '内向送货单号', code: 'reverseOrderCode', type: 'input', width: '65%' },
  { label: '发布数量', code: 'livraisonTotalNumber', type: 'input', width: '50%' },
  { label: '预计送达日期', code: 'expectTime', type: 'input', width: '65%' },
  { label: '操作人', code: 'createName', type: 'input', width: '55%' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '45%',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(10, 16)
      const label = data.status === 0 ? '已发布' : data.status === 1 ? '已发货' : data.status === 2 ? '已作废'
        : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '55%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      },
      {
        actionLabel: '发货',
        operationButton: 'offer',
        stateField: 'status',
        stateSymbol: '=',
        stateValue: '0'
      }
    ]
  }
]

export var tableData = []
